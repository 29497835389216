<script>
export default {
  render(createElement) {
    // The content won't be displayed to the, by just in case.
    return createElement('div', 'Logging out...');
  },
  mounted() {
    this.logout();
  },
  methods: {
    async logout() {
      await this.$post({
        url: `${this.$baseurl}/logout`,
        headers: this.headers,
        success: () => this.clearSession(),
        error: () => this.clearSession(),
      });
    },
    clearSession() {
      return this.$store
        .dispatch('session/logout')
        .then(() =>
          this.$router.push({ name: 'login', query: this.$route.query })
        );
    },
  },
};
</script>
